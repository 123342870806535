<template>
  <div>
    <b-row>
      <b-col
        v-for="cate in cates"
        :key="cate.cate.id"
        cols="12"
        md="12"
      >
        <h6 class="my-2 text-muted">
          {{ cate.cate.name }}
        </h6>

        <b-card-text>
          {{ cate.cate.info }}
        </b-card-text>

        <b-row>
          <b-col
            v-for="plan in cate.plans"
            :key="plan.ID"
            md="6"
            lg="4"
          >
            <b-card
              :title="plan.name"
              :sub-title="plan.info"
              class="mb-4"
            >
              <b-card-text>
                Price: {{ plan.price }}/年
              </b-card-text>
              <b-link
                class="card-link"
                @click="order(plan.id)"
              >
                获取
              </b-link>
            </b-card>   </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>

</template>

<script>
import Ripple from 'vue-ripple-directive'
import useHttp from '@/http/useHttp'
import { notify, throwError } from '@/utils/utils'
import {
  BRow, BCol, BCard, BCardText, BLink,
} from 'bootstrap-vue'

export default {
  components: {
    BRow, BCol, BCard, BCardText, BLink,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      cates: {},
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      await useHttp.getMailXPlans().then(res => {
        // const { data } = res.cate
        this.cates = res.data
        // console.log(this.cates)
      }).catch(error => {
        throwError(error)
        notify('Error', 'get Plans error', 'danger')
      })
    },
    order(id) {
      this.$router.push({ name: 'mailx-plan-info', params: { id } })
    },
  },
}
</script>

<style>

</style>
